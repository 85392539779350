.plant-list-sider-wrapper {
  width: 380px;
  // margin: 16px 40px 0 20px;
  flex: 1;

  .plant-card {
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.9);

    .card-item {
      display: flex;
      gap: 10px;
      align-items: center;

      .card-icon {
        width: 46px;
        height: 46px;
        display: flex;
        place-content: center;
        align-items: center;
        border-radius: 2px;
        background-color: rgba(23, 125, 225, 0.202464);
      }

      .card-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;

        .card-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #323233;
        }

        .card-data {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: #177de1;
        }
      }
    }
  }
}

// .plant-count {
//   height: 80px;
//   background-color: #ecb033;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0px 10px;
//   p {
//     display: flex;
//     flex-direction: column;
//   }
//   span {
//     margin: 2px 0px;
//   }
//   span:first-child {
//     font-family: Roboto;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 19px;
//     letter-spacing: 0px;
//     text-align: center;
//   }
//   span:last-child {
//     font-family: Roboto;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 21px;
//     letter-spacing: 0px;
//     text-align: center;
//   }
// }
.plants-menu {
  padding: 12px 16px;
  height: calc(100vh - 343px);
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.plants-sub-menu {
  font-size: 14px;
  font-weight: 600;
  color: rgba(161, 161, 161, 1);
  margin-top: 17px;
}

// .plant-item-wrapper:first-child {
//   border-top: 1px solid #e7e7e7;
// }
.plant-item-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  height: 60px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  transition: ease-in-out 0.18s;
  user-select: none;
  border: 1px solid #646566;
  margin: 4px 0;

  &:hover {
    border: 1px solid #177de1;

    &>.item-order {
      color: #177de1;
    }

    &>.item-details {
      .item-name {
        color: #177de1;
      }

      .item-time {
        color: rgba(23, 125, 225, 0.8);
      }
    }
  }

  &>.item-order {
    flex: 0;
    // flex-basis: 40px;
    text-align: center;
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    font-weight: 600;
    color: #323233;
  }

  &>.item-details {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .item-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #323233;
    }

    .item-time {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(50, 50, 51, 0.8);
    }
  }

  // & > .item-details {
  //   flex: 1;
  //   height: 72px;
  //   position: relative;
  //
  //   &:after {
  //     position: absolute;
  //     left: 24px;
  //     right: 24px;
  //     border-bottom: 1px solid rgba(231, 231, 231, 1);
  //   }
  //   .item-time {
  //     height: 22px;
  //     font-size: 12px;
  //     color: rgba(155, 155, 155, 1);
  //     line-height: 22px;
  //   }
  //   .item-name {
  //     height: 22px;
  //     font-size: 16px;
  //     color: rgba(83, 83, 83, 1);
  //     line-height: 22px;
  //   }
  //
  //   .item-address {
  //     height: 17px;
  //     font-size: 12px;
  //     color: rgba(155, 155, 155, 1);
  //     line-height: 17px;
  //     text-overflow: ellipsis;
  //     overflow: hidden;
  //     max-width: 280px;
  //   }
  // }
}

// hack
.ant-menu-item {
  margin: 0 !important;
  padding: 0 !important;
  max-height: unset !important;
  height: unset !important;
  line-height: unset !important;
}

.ant-menu-submenu-title {
  padding: 0 !important;
}
