.loading-wrapper {
   flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9;
  color: black;
  user-select: none;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  top: 00vh;
  left: 00vw;
  .content {
    font-size: 16px;
  }
  .anticon-loading{
    color: #eaa516;
  }
}
