.live-view-out-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 260px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 99;

  .live-folder {
    transition: none;
    border-color: rgba(26, 26, 26, 0.74);
    background: rgba(26, 26, 26, 0.74) !important;
    color: white;
  }

  .live-view-wrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(26, 26, 26, 0.74);

    &.fold {
      .live-content {
        height: 0;
      }
    }

    .live-content {
      height: calc((100vh - 32px * 2 - 73px - 80px) / 3);
      display: flex;
    }
  }
}
