.header-wrapper {
    position: relative;
    padding: 16px 20px;
    // margin-bottom: 5px;
    height: 64px;
    line-height: 50px;
    background-color: #fff;
    /* background-color: #0a0523; */
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    // justify-content: end;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    // img {
    //   margin: 0px 5px;
    //   width: 146px;
    //   height: 16px;
    //   cursor: pointer;
    // }
    // .dropdown {
    //   height: 40px;
    //   line-height: 40px;
    //   margin: 0px 10px;
    //   width: 100px;
    //   box-sizing: border-box;
    //   background: #e3c122;
    //   border-radius: 4px;
    //   border-color: transparent;
    //   color: black;
    //   border: none;
    //   span {
    //     margin-left: 5px;
    //   }
    //   &:hover {
    //     background-color: rgb(94, 94, 94);
    //     color: white;
    //     cursor: pointer;
    //     border: none;
    //   }
    //   z-index: 3;
    // }

    img {
        width: 30px;
        height: 30px;
    }

    .title {
        flex: 1;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        /* color: #fff; */
    }

    .jump {
        cursor: pointer;

        a {
            text-decoration: none;
            outline: none;
            color: #000;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .btn-group {
        position: absolute;
        display: flex;
        align-items: center;
        gap: 16px;
        right: 20px;
        top: 80px;
        background: #fff;
        border-radius: 6px;
        padding: 8px;
        z-index: 10;
    }
}
