.detail-viewer-wrapper {
  display: flex;
  flex-flow: row nowrap;
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
   position: absolute;
  z-index: 999;
  // width: 100%;
  // height: 100%;
  .image-loading-wrapper {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
  }
  .image-loading {
    font-size: 46px;
    color: rgb(255, 174, 0);
  }
  .image-light-container,
  .image-infrared-container {
    width: 50%; 
    overflow: hidden;
    color: white;
    position: relative;
  }

  .image-infrared-container::after{
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
  }


  .reset-button {
    position: absolute;
    left: 30px;
    top: 30px;
  }
}

/* copy form gearware@0.1.23 by caperso */
.g-image-preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0 !important;
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
}

.viewer-canvas,
.g-image-preview-image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50%;
  transform: translate(-50%,-50%);
  cursor: move;
}
