.plant-info-sider-wrapper {
  width: 380px;
  flex: 1;
  // background: rgba(242, 242, 242, 1);
  border-radius: 4px;
  position: relative;

  .plant-info-header {
    // height: 200px;
    // background-color: #ecb033;
    // display: flex;
    // flex-direction: column;
    // padding: 0px 15px;
    border-radius: 4px;
    background-color: rgba(10, 5, 35, 0.9);
    margin-bottom: 10px;
  }
  .plant-back {
    // margin-top: 10px;
    cursor: pointer;
    // border-radius: 5px;
    display: flex;
    //padding: 0px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    // justify-content: space-between;
    gap: 10px;
    align-items: center;
    // background-color: rgba(148, 124, 40, 1);
    height: 40px;
    padding: 12px 20px;
  }
  .plant-back:hover {
    //margin-top: 10px;
    // background-color: ;
    cursor: pointer;
  }
  h2 {
    //margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
  }
  p {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    height: 20px;
    align-items: center;

    .p-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.8);
      mix-blend-mode: normal;
    }

    .p-value {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
    }
  }

  .plant-info {
    padding: 20px;
  }
}
