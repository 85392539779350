.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  flex: 1;

  .map-control-filter {
    position: absolute;
    top: 20px;
    right: 140px;
    z-index: 8;
    height: 40px;
    // width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;

    .ant-dropdown-trigger,
    .ant-radio-button-wrapper {
      // background-color: rgba(29, 34, 43, 0.5);
      background-color: rgba(255, 255, 255, 0.9);
      // color: white;
      color: #323233;
      margin: 0px 3px;
      border: none;
      width: 80px;

      padding: 2px 0;
      text-align: center;
      border-radius: 4px;

      &:hover {
        // background-color: rgb(255, 197, 12);
        background-color: #177de1;
        color: #fff;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: #177de1;
        color: #fff;
        // color: rgb(255, 197, 12);
        border: none;
        // background-color: rgba(23, 28, 37, 0.92);
      }

      &:not(:first-child)::before {
        content: unset;
      }
    }

    .ant-input {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

.map-wrapper.mini {
  transition: all 0.35s;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 300px;
  max-height: 300px;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  z-index: 9999;
}

// .map-hover-button,
// .map-expand-button {
//   position: fixed;
//   right: 30px;
//   top: 70px;
//   height: 30px;
//   z-index: 999;
//   width: 30px;
//   line-height: 30px;
//   background-color: rgba(1, 1, 1, 0.3);
//   border: rgba(1, 1, 1, 0.7) 1px solid;
//   color: #dcbe52;
//   font-size: 18px;
//   cursor: pointer;
//   border-radius: 4px;
//   padding: 0;
//   transition: all 0.3s;
//   &:hover {
//     background: rgba(1, 1, 1, 1);
//     border: rgba(1, 1, 1, 1) 1px solid;
//     color: white;
//   }
// }

.tap {
  position: absolute;
  z-index: 1;
  background-color: rgb(52 51 51 / 80%);
  border-radius: 2%;
  font-size: 4px;
  width: 140px;
  padding: 10px;

  p {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
  }

  .triangle {
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    .ant-btn {
      background-color: #eaa516;
      height: 20px;
      line-height: 20px;
      padding: 0px 10px;

      &:hover {
        color: #000;
      }
    }
  }
}

.ant-dropdown-menu-item-selected {
  color: #177de1 !important;
}
