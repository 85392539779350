.detail-history-wrapper {
  position: absolute;
  right: 0;
  z-index: 99;
  width: 380px;
  margin-top: 60px;
  // height: 240px;
  overflow-y: auto;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
}

.current-point-info {
  height: 240px;
  width: 380px;
  padding: 10px;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.current-point-name {
  display: flex;
  flex-direction: column;
  p {
    margin: 5px 0px;
  }
}
.current-photo-wrapper {
  // p {
  //   margin: 10px 0px;
  // }
  .current-photo-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  .current-photo-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .current-photo-infrared,
  .current-photo-light {
    position: relative;
    display: flex;
    flex-direction: column;

    .report-detail-canvas {
      position: absolute;
      left: 0;
      top: 10;
    }
    img {
      width: 180px;
      height: 154px;
    }
  }
}
.image-loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
.image-loading {
  font-size: 46px;
  color: rgb(255, 174, 0);
}
.current-photo-history {
  display: flex;
  justify-content: center;
  button {
    padding: 0px 10px;
  }
}
