.login-wrapper {
  user-select: none;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background-image: url("../../assets/image/login-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login-container {
  width: 380px;
  // height: 411px;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
}

.login-company-logo-wrapper,
.login-company-cover {
  width: 476px;
  min-width: 476px;
  height: 660px;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
}

.login-company-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.login-company-logo {
  width: 260px;
  height: 260px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-company-logo-wrapper {
  position: relative;

  .login-company-title {
    position: absolute;
    bottom: 200px;
    width: 100%;
    & > p {
      width: 100%;
      height: 33px;
      line-height: 33px;
      font-size: 26px;
      font-weight: 700;
      text-align: center;
    }

    & > p:last-child {
      padding-top: 20px;
      height: 24px;
      line-height: 24px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.login-main {
  // padding: 58px 66px 22px;
  padding: 60px 40px;
  width: 100%;
  position: relative;

  .title {
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #202426;
    text-align: center;
    margin-bottom: 40px;
  }
  // h1 {
  //   text-align: center;
  //   height: 33px;
  //   line-height: 33px;
  //   font-size: 24px;
  //   font-weight: 400;
  //   color: rgba(0, 0, 0, 0.87);
  //   margin-bottom: 37px;
  // }
  //
  // h2 {
  //   text-align: center;
  //   height: 30px;
  //   line-height: 30px;
  //   font-size: 30px;
  //   font-weight: 600;
  //   color: rgba(0, 0, 0, 0.87);
  //   margin-bottom: 56px;
  // }

  .login-form {
    .login-input {
      width: 100%;
      display: flex;
      justify-content: center;
      &.username {
        // margin-bottom: 36px;
        margin-bottom: 30px;
      }
      &.password {
        // margin-bottom: 25px;
        margin-bottom: 40px;
      }

      .ant-form-item-row {
        width: 100%;
      }
    }
    .login-auto-login {
      margin-bottom: 38px;
    }
    .login-button {
      // width: 394px;
      width: 100%;
      height: 40px;
      // background: rgba(220, 190, 82, 1);
      background-color: #177de1;
      // border: rgba(220, 190, 82, 1) 1px solid;
      // border-radius: 2px;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;

      // font-size: 14px;
      // font-weight: 600;
      // color: rgba(91, 91, 91, 1);
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;

      &:hover {
        background-color: rgb(214, 138, 24);
      }
    }
  }

  .login-copyrights {
    position: absolute;
    align-items: flex-end;
    left: 50%;
    transform: translateX(-50%);
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: rgba(181, 181, 181, 1);
    bottom: 22px;
    white-space: nowrap;
  }
}
