.not-found-wrapper {
  width: 40%;
  margin: 10% auto 0;
  text-align: center;
  & > div {
    height: 300px;
    width: 300px;
    margin: 0 auto;
    background-image: url('../../assets/image/404.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 120px;
  }
  &>h2{
      margin-bottom:  40px;
  }
  a {
    color: #2196f3;
    &:hover {
      text-decoration: underline;
    }
  }
}
