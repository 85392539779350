html {
  font-family: "Puhuiti";
}

body {
  // font-family:
  //   Puhuiti,
  //   -apple-system,
  //   BlinkMacSystemFont,
  //   "Segoe UI",
  //   "Roboto",
  //   "Oxygen",
  //   "Ubuntu",
  //   "Cantarell",
  //   "Fira Sans",
  //   "Droid Sans",
  //   "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    max-height: 100vh;
    height: 100vh;

    .App {
      max-height: 100vh;
      height: 100vh;
      display: flex;
      flex-direction: column;
    }
  }
}

/* scroll bar */

::-webkit-scrollbar {
  width: 10px; //请不要改小
  height: 6px;
  background-color: #cccccc;

  &-track {
    /*滚动条里面轨道*/
    border-radius: 2px;
    background-color: #eee;
  }

  &-thumb {
    /*滚动条里面小方块*/
    border-radius: 2px;
    background-color: #c1c1c1;

    &:hover {
      background-color: #9f9f9f;
    }
  }
}

/* cesium */
.cesium-viewer-toolbar,
.cesium-viewer-bottom {
  display: none;
}

.ant-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-modal {
  top: 30% !important;
}

.ant-input,
.ant-input-password {
  border-radius: 4px !important;

  &::placeholder {
    color: #646566 !important;
  }
}
