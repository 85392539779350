.plants-wrapper {
  flex: 1;
  position: relative;
}

.plants-layout {
  position: relative;
  flex: 1;

  .sidebar {
    position: absolute;
    top: 20px;
    left: 20px;
    bottom: 20px;
  }

  .plants-content {
    position: relative;
  }
}
.plants-content {
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
  overflow: hidden;
  overflow-y: auto;
  background: rgba(255, 255, 255, 1);
}

// .sider-wrapper {
//   width: 380px;
//   background: rgba(255, 255, 255, 1);
// }
