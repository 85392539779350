.current-history-info {
  // height: 300px;
  width: 420px;
  // padding: 16px;
  // background-color: #ffff;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;

  .current-task-name {
    padding: 16px;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #323233;
    }
  }
}
// .current-history-name {
//   display: flex;
//   justify-content: space-between;
//   margin: 5px 0px;
//   font-size: 12px;
// }
.current-history-wrapper {
  padding: 16px;

  .current-history-name {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      font-weight: 400;
      line-height: 17px;
      color: #646566;
      font-size: 12px;
      word-break: break-all;
      word-wrap: break-word;
    }
    // margin: 10px 0px;
  }
  .current-history-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .current-history-infrared,
  .current-history-light {
    position: relative;
    display: flex;
    flex-direction: column;

    .report-detail-canvas {
      position: absolute;
      left: 0;
      top: 10;
    }
    img {
      width: 192px;
      height: 154px;
    }
  }
}
.image-loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
.image-loading {
  font-size: 46px;
  color: rgb(255, 174, 0);
}
