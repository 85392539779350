.history-banner-wrapper {
  position: absolute;
  right: 20px;
  top: 80px;
  // height: 94%;
  width: 420px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  .shrink-btn {
    position: absolute;
    top: 13px;
    right: 46px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    width: 30px;
    height: 30px;
    border: none;
    z-index: 999;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .detail-history-wrapper {
    width: 420px;
    margin-top: 20px;
    max-height: calc(100vh - 512px);
    overflow-y: auto;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .current-point-info {
    // margin-top: 10px;
    height: 100%;
    width: 420px;
    padding: 0;
    // background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 4px;

    background-color: rgba(255, 255, 255, 0.9);

    .banner-header {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      .current-point-title {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .header-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #323233;
        }

        .header-subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #323233;
        }
      }
    }
  }
  // .current-point-name {
  //   display: flex;
  //   justify-content: space-between;
  // }
  .current-photo-wrapper {
    padding: 16px;
    // p {
    //   margin: 10px 0px;
    // }
    .current-photo-name {
      display: flex;
      justify-content: space-around;
      gap: 8px;
      align-items: center;
      // margin: 10px 0px;
      margin: 4px auto 8px;

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #646566;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    .current-photo-image {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .current-photo-infrared,
    .current-photo-light {
      position: relative;
      display: flex;
      flex-direction: column;

      .report-detail-canvas {
        position: absolute;
        left: 0;
        top: 10;
      }
      img {
        width: 192px;
        height: 154px;
      }
    }
  }
  .image-loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
  }
  .image-loading {
    font-size: 46px;
    color: rgb(255, 174, 0);
  }
  .current-photo-history {
    display: flex;
    justify-content: center;
    button {
      background-color: #177de1;
      color: #fff;
      width: 100%;
      border-radius: 4px;
    }
  }
}

.map-hover-button {
  top: 70px;
}
// .map-expand-button {
//   top: 30px;
// }
.map-hover-button,
.map-expand-button {
  // position: absolute;
  // right: 30px;
  // height: 30px;
  // z-index: 999;
  height: 20px;
  width: 20px;
  line-height: 20px;
  // background-color: rgb(1 1 1 / 60%);
  // border: rgba(1, 1, 1, 0.7) 1px solid;
  // color: #dcbe52;
  // font-size: 18px;
  cursor: pointer;
  border: none;
  background: none;
  border-radius: 4px;
  padding: 0;
  transition: all 0.3s;
  box-shadow: none;
  margin-left: 8px;
  &:hover {
    // background: rgba(1, 1, 1, 1);
    // border: rgba(1, 1, 1, 1) 1px solid;
    color: white;
    background: none;
  }
}
