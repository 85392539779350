.live-reload-wrapper {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 5px;
  top: 5px;
  text-align: center;
  font-size: 28px;
  cursor: pointer;
  z-index: 100;
  color: gray;
  &:hover {
    color: rgb(250, 215, 90);
  }
}
