.detail-map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  flex: 1;

  .plant-info-header {
    z-index: 88;
    position: absolute;
    top: 0;
    width: 380px;
    height: 200px;

    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    .task-info {
      background: rgba(17, 17, 17, 0.8);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      p {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        span {
          margin: 5px 0px;
        }
      }
    }

    .plant-info {
      // background-color: #ecb033;
      background-color: #177de1;
      padding: 10px;

      h2 {
        margin-top: 10px;
        font-size: 20px;
        color: #fff;
      }
      p {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        height: 20px;
        align-items: center;
        color: #fff;
      }
    }
  }
  .map-hover-button,
  .map-expand-button {
    position: fixed;
    // right: 30px;
    top: 70px;
    height: 30px;
    z-index: 999;
    width: 30px;
    line-height: 30px;
    // background-color: rgba(1, 1, 1, 0.6);
    background-color: rgba(255, 255, 255, 0.6);
    // border: rgba(1, 1, 1, 0.7) 1px solid;
    // color: #dcbe52;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    padding: 0;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      // background: rgba(1, 1, 1, 1);
      // border: rgba(1, 1, 1, 1) 1px solid;
      color: white;
    }
  }
  .map-control-filter {
    position: absolute;
    top: 10px;
    left: 380px;
    z-index: 88;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    .ant-dropdown-trigger,
    .ant-radio-button-wrapper {
      // background-color: rgba(29, 34, 43, 0.5);
      // color: white;
      color: #323233;
      background-color: rgba(255, 255, 255, 0.92);
      margin: 0px 3px;
      border: none;
      width: 80px;

      padding: 0;
      text-align: center;
      border-radius: 3px;
      &:hover {
        background-color: rgb(23, 125, 255);
        color: #fff;
      }
      &.ant-radio-button-wrapper-checked {
        // color: rgb(255, 197, 12);
        color: #fff;
        border: none;
        // background-color: rgba(23, 28, 37, 0.92);
        background-color: rgba(23, 125, 225, 1);
      }
      &:not(:first-child)::before {
        content: unset;
      }
    }
  }
}
.detail-map-wrapper.mini {
  transition: all 0.35s;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 300px;
  max-height: 300px;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  z-index: 9999;
}
.controls {
  z-index: 99;
  right: 0;
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  bottom: 15px;
  display: flex;
  font-size: 24px;
  span {
    margin: 5px;
  }
}
