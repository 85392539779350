.tasks-list-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 16px;

  .tasks-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 0;
    // letter-spacing: 0.5px;
    text-align: left;
    height: 20px;
    color: #323233;
  }

  .tasks-box {
    overflow-y: auto;
    height: calc(100vh - 352px);
    display: flex;
    flex-direction: column;
    gap: 8px;

    .list-item {
      width: 100%;
      // height: 70px;
      // padding: 15px 16px 14px;
      padding: 10px;
      cursor: pointer;
      border-radius: 4px;
      // display: flex;
      // flex-flow: row nowrap;
      // justify-content: space-between;
      // align-items: center;
      border: 1px solid #646566;

      &:hover,
      &.selected {
        border: 1px solid #177de1;
        // background-color: #fdf0c5;

        .tasks-item {
          .tasks-info {
            .tasks-name {
              color: #177de1;
            }
            .tasks-date {
              color: rgba(23, 125, 225, 0.8);
            }
          }

          .tasks-share {
            button {
              border: 1px solid #177de1;

              span {
                color: #177de1;
              }
            }
          }
        }
      }
      .tasks-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        gap: 8px;
        position: relative;
      }
      .tasks-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .tasks-share {
        // width: 140px;
        height: 28px;
        display: flex;
        align-self: flex-end;
        // justify-content: space-around;
        // align-items: center;
        gap: 8px;
        position: absolute;
        // top: 10px;
        // right: 10px;

        button {
          height: 28px;
          width: 72px;
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #646566;
          // background-color: rgba(255, 255, 255, 0.9);
          background: none;

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #323233;
          }
        }
      }
      .tasks-name {
        // font-family: Roboto;
        font-size: 14px;
        line-height: 20px;
        font-style: normal;
        margin-bottom: 2px;
        font-weight: 500;
        // letter-spacing: 0.5714284777641296px;
        text-align: left;
        color: #323233;
        // margin: 5px 0px;
      }
      .tasks-date {
        // font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        color: rgba(50, 50, 51, 0.8);
        // letter-spacing: 0.42857131361961365px;
        text-align: left;
        // color: #b5b5b5;
        margin-top: 8px;
        white-space: nowrap;
      }
    }
  }

  // .list-item :first-child {
  //   border-top: 1px solid #e7e7e7;
  // }
}
.ant-popover-title {
  min-width: 160px !important;
}
